/*
	Authentication
	Utilities for login/registration modal functionality.

	Error codes:
		1:		Initial login modal content failed to load
		2:		AJAX request failed and no specific error message was provided
		3:		Multi-factor authentication failed
		4:		Unable to process error, no JSON provided from server
		5:		Unspecified internal server error
		6:		Login help error
		7:		Update contact info error
		8:		Error changing password
		9:		Unhandled input validation error
*/

function Authentication()
{
	let self								= this,
		genericErrorText					= 'A system error occurred, please try again. If this issue persists, please call <a href="tel:+1-888-478-8626" class="nobr">1-888-478-8626</a> for assistance.',
		$loaderOverlay						= $("<div class='putnam-loader-wrapper putnam-loader-overlay'><div class='putnam-loader-sm'></div></div>"),
		initialAuthenticationModalObject	= {},
		errorModalObject					= {
			modalTitle:							'System error',
			modalClass:							'auth-modal-error auth-modal-generic-error',
			modalHard:							true,
			modalBody:							'<p>' + genericErrorText + '</p>',
			modalFooter:						'<button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>'
		};

	if ( application.site == "individual" || application.site == "advisor" ) {
		self.identityProvider = PutnamIdentityProvider.getInstance();
	}

	// Ternary to set self.loggedIn to true or false based on the meta tag "loggedIn"
	self.loggedIn = ( $('meta[name="loggedIn"]').attr('content') == "true" ) ? true : false;
	
	// Initialize some timers
	self.loginTimer			= null;
	self.mfaRequestTimer	= null;
	self.mfaInputTimer		= null;
	self.lexisNexisTimer	= null;
	self.finalTimeoutTimer	= null;

	self.timeoutMinutes = 0;

	// LexusNexis variables
	self.lexusNexisQuestionTimeoutModal = undefined;

	self.lexusNexisTimeAllowed = 240;
	
	// Store user intent for error processing
	self.userIntent = "";

	/*
		Ready
	*/
	self.ready = function()
	{
		if (
			self.loggedIn &&
			( application.site == "individual" || application.site == "advisor" )
		)
		{
			// If the user is logged in, we need to set up the idle timer
			self.identityProvider.loggedIn();

			self.identityProvider.registerEventListener( "idle", self.handleIdleTimer );

		}

		$(document).on( 'click', '.start-login-process', function( e ) {
			e.preventDefault();
			self.start();
		});

		$(document).on( 'click', '.start-registration-process', function( e ) {
			e.preventDefault();
			self.start( 'register' );
		});

		$(document).on( 'click', '.accounts-logout', function( e ) {
			e.preventDefault();
			self.logout();
		});

		$(document).on( 'click', '.accounts-extend-session', function( e ) {
			e.preventDefault();
			self.extendCurrentSession();
		});

		$(document).on( 'formValid', 'form.auth-modal-form', function() {
			let $form			= $(this),
				ajaxURL			= $form.attr('action'),
				method			= $form.attr('method'),
				onSuccess		= $form.data('on-success'),
				onError			= $form.data('on-error'),
				ajaxDataType	= 'html',
				ajaxContentType	= 'application/x-www-form-urlencoded',
				ajaxCustomData	= $form.data('ajax-custom-data');

			// Strip phone inputs of non-numeric characters, excluding "+"
			$form.find('input[type="tel"]').each( function() {
				let $input = $(this);
				$input.val( $input.val().replace(/[^0-9+]/g, '') );
			});

			let formData = $form.serialize();

			if ( ajaxCustomData == 'lexisNexisVerifyCustomData' ) {
				formData = JSON.stringify( JSON.parse( $form.find('input#completed-quiz-data-object').val() ) );
			}

			ajaxDataType	= $form.data('ajax-data-type');
			ajaxContentType	= $form.data('ajax-content-type');

			// Show loader
			$form.closest('.modal-body').append( $loaderOverlay );

			// Disable the submit button to prevent multiple submissions
			$form.find('button[type="submit"]').prop('disabled', true);

			// Hide error messages if they're visible to prevent incorrect error messages on subsequent submits
			$form.find('[id^=verify-error-]').hide();
			$form.find('[id^=session-expired-]').hide();
			$form.find('.server-error-text').hide();

			// Submit form
			// ajax form to the server
			$.ajax({
				url:			ajaxURL,
				type:			method,
				data:			formData,
				dataType:		ajaxDataType,
				contentType:	ajaxContentType,
				headers: {
					'X-XSRF-TOKEN': $.cookie('XSRF-TOKEN')
				},
				xhrFields: {
					withCredentials: true
				},
				success: function( data ) {
					self.processSuccess( data, onSuccess, $form );
				},
				error: function( data ) {
					self.processError( data, onError, $form );
				}
			});
		});

		if ( application.site == "individual" ) {
			let initialAuthenticationHeaderContent	= "Login",
				initialAuthenticationModalContent	= "",
				initialAuthenticationFooterContent	= "",
				initialAuthenticationHard			= false,
				sessionExpired						= sessionStorage.getItem( 'sessionExpired' );

			// Preload initial login modal content to avoid delay on first click
			$.ajax({
				url:		'/individual/auth/accounts/login',
				type:		'GET',
				async:		false,
				headers: {
					'X-XSRF-TOKEN': $.cookie('XSRF-TOKEN')
				},
				xhrFields: {
					withCredentials: true
				},
				success:	function( data ) {
					initialAuthenticationModalContent = data;
				},
				error:		function( data ) {
					initialAuthenticationHeaderContent	= 'System error';
					initialAuthenticationModalContent	= '<p>' + genericErrorText + ' [Error code: 1]</p>';
					initialAuthenticationFooterContent	= '<button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>';
					initialAuthenticationHard			= true;
				}
			});

			initialAuthenticationModalObject = {
				modalTitle:		initialAuthenticationHeaderContent,
				modalClass:		'auth-modal-login',
				modalHard:		initialAuthenticationHard,
				modalBody:		initialAuthenticationModalContent,
				modalFooter:	initialAuthenticationFooterContent
			};

			// If session expired, show the login modal and display the session expired message
			if ( sessionExpired ) {
				
				// Show the next modal
				application.dynamicModal.show( {
					modalTitle:			'Login',
					modalAjaxURL: 		'/individual/auth/accounts/login',
					modalAjaxType:		'post',
					modalAjaxWait:		true
				} );
			}
		}

		if ( application.site == "individual" || application.site == "advisor" ) {
			
			// Set the last login element if the user is logged in
			if ( self.loggedIn ) {

				// Get the JSON profile data from /{site}/profile and check the idpType value, if it is NOT "WORKFORCE", show the last login element
				$.ajax({
					url:		'/' + application.site + '/profile',
					type:		'GET',
					dataType:	'json',
					headers: {
						'X-XSRF-TOKEN': $.cookie('XSRF-TOKEN')
					},
					xhrFields: {
						withCredentials: true
					},
					success:	function( data ) {
						if ( data.idpType !== "WORKFORCE" ) {
							PutnamIdentityProvider.getInstance().getLastLoginEvents().then( function( data ) {
								let $lastLoginElements	= $('.last-login'),
									dataToUse			= data[0],
									lastLoginDate		= '',
									lastLoginIP			= '',
									lastLoginIPString	= '';
			
								// If dataToUse is not undefined
								if ( dataToUse !== undefined ) {
								
									// Use the second item in the array if it exists, this will be the PREVIOUS login, first one is current login, usually
									if ( data[1] ) {
										dataToUse = data[1];
									}
			
									// Convert 2023-08-17T14:02:36.138Z to 08/17/2023
									if ( dataToUse.loginDate ) {
										lastLoginDate = dataToUse.loginDate.split('T')[0].split('-')[1] + '/' + dataToUse.loginDate.split('T')[0].split('-')[2] + '/' + dataToUse.loginDate.split('T')[0].split('-')[0];
									}
									
									// Set the IP address if it exists to show as the title attribute, users will see it if they hover long enough
									if ( dataToUse.ipAddress ) {
										lastLoginIP = dataToUse.ipAddress;
										lastLoginIPString = 'From IP address: ' + lastLoginIP;
									}
			
									$lastLoginElements.each( function() {
										let $this = $(this);
			
										$this
											.removeClass('hidden')
											.find('span')
											.text('Last login on ' + lastLoginDate)
											.attr('title', lastLoginIPString);
									});
								}
							});
						}
					},
					error:		function( data ) {
						console.log( 'Error getting profile data' );
					}
				});
			}
		}
	};

	/*
		Start
	*/
	self.start = function( action )
	{
		// Start by clearing out any previous login attempts
		self.clearAuthentication();

		//  Reset the LN time allowed
		self.lexusNexisTimeAllowed = 240;

		if ( application.site == "individual" ) {

			// If action is defined and is "register", show the registration modal
			if ( action && action == "register" ) {
				
				application.dynamicModal.show( {
					modalTitle:		'Register',
					modalClass:		'auth-modal-register',
					modalHard:		true,
					modalAjaxURL: 	'/individual/auth/accounts/register',
					modalAjaxType:	'post',
					modalBody:		"<div class='putnam-loader-wrapper' style='min-height:413px;'><div class='putnam-loader-sm'></div></div>"
				} );
			} else {

				// If already logged in, go to /individual/accounts
				if ( self.loggedIn ) {
					window.location.href = '/individual/accounts';
				} else {
					// Show initial login modal
					sessionStorage.removeItem('sessionExpired');
					application.dynamicModal.show( initialAuthenticationModalObject );

					let $form = $('.modal form.auth-modal-form');

					self.monitorInputsAndControlSubmitButton( $form );
				}
			}
		}
	};

	/* self.authenticationStep() = function( $form ) { */

	self.processSuccess = function( data, onSuccess, $form )
	{
		switch ( onSuccess ) {

			case 'loginSuccess':
				if ( data.status == 'SUCCESS' ) {  // No issues, redirect to accounts page
					self.loginComplete();
				} else if ( data.status == 'REQUEST_MFA' ) {  // MFA required
					// Store the result in session storage
					sessionStorage.setItem( 'mfaRequestResult', JSON.stringify( data ) );

					// Show the next modal
					application.dynamicModal.show( {
						modalTitle:		'Verify your identity',
						modalClass:		'auth-modal-login-success auth-modal-request-mfa',
						modalHard:		true,
						modalAjaxURL: 	'/individual/auth/accounts/login-mfa-request-code',
						modalAjaxType:	'post',
						modalAjaxData:	data,
						modalAjaxWait:	true
					} );
				} else if ( data.status == 'STAGED_USER' ) {  // Staged user
					// Store the email in session storage
					sessionStorage.setItem( 'registerNewEmail', data.userLogin );
					
					// Show the "Set password" modal
					application.dynamicModal.show( {
						modalTitle:			'Login credentials',
						modalClass:			'auth-modal-login-success auth-modal-staged-user',
						modalHard:			true,
						modalAjaxURL: 		'/individual/auth/accounts/login-set-password',
						modalAjaxType:		'post',
						modalAjaxData:		data,
						modalAjaxWait:		true,
						modalAjaxSuccess:	function() {
							let $newlyLoadedForm = $('.modal form.auth-modal-form');
							self.monitorInputsAndControlSubmitButton( $newlyLoadedForm );
						}
					} );
				} else if ( data.status == 'CSU_USER_ALREADY_REGISTERED' ) {  // CSU user already registered')
					// Store the email in session storage
					sessionStorage.setItem( 'foundExistingEmail', data.userLogin );

					// Show the next modal
					application.dynamicModal.show( {
						modalTitle:			'Login',
						modalClass:			'auth-modal-login-success auth-modal-csu-user-already-registered',
						modalHard:			true,
						modalAjaxURL: 		'/individual/auth/accounts/login',
						modalAjaxType:		'post',
						modalAjaxWait:		true,
						modalAjaxSuccess:	function() {
							let $modal = $('.modal'),
								$newlyLoadedForm = $('.modal form.auth-modal-form');

							// Set the server-error-text text and make it visible
							$modal.find('.server-error-text').text( 'Your username has changed. Please login with your email address and password.' ).show();

							self.monitorInputsAndControlSubmitButton( $newlyLoadedForm );
						}
					} );

				}

				break;

			case 'registerSuccess':
				// Store the email in session storage
				sessionStorage.setItem( 'registerNewEmail', $form.find('input.form-control-email').val() );

				if ( data.status == 'LEXIS_NEXIS_VERIFY' ) {  // Go to LexisNexis flow to verify identity

					console.log('data.stateExpiresInSecs', data.stateExpiresInSecs)
					if( data.stateExpiresInSecs !== null ) {
						this.lexusNexisTimeAllowed = Math.min( 180, data.stateExpiresInSecs );
						console.log( 'new lexusNexisTimeAllowed:', this.lexusNexisTimeAllowed, data.stateExpiresInSecs )
					}

					// Store LexisNexis verification challenge in session storage
					sessionStorage.setItem( 'lexisNexisChallenge', JSON.stringify( data.userVerificationChallenge ) );

					self.lexusNexisQuestionTimeoutModal = {
						modalTitle:		'Register',
						modalClass:		'auth-modal-register-success auth-modal-lexis-nexis-timed-out',
						modalHard:		true,
						modalAjaxURL: 	'/individual/auth/accounts/register',
						modalAjaxType:	'post',
						modalBody:		"<div class='putnam-loader-wrapper' style='min-height:413px;'><div class='putnam-loader-sm'></div></div>"
					};

					// Show the "LexisNexis questions" modal
					application.dynamicModal.show( {
						modalTitle:		'Verify your identity <span class="time-remaining">(' + self.getPrettyTimeRemaining() + ')</span>',
						modalClass:		'auth-modal-register-success auth-modal-lexis-nexis-verify',
						modalHard:		true,
						modalAjaxURL: 	'/individual/auth/accounts/verify-identity-questions',
						modalAjaxType:	'post',
						modalAjaxData:	data,
						modalAjaxWait:	true
					} );

				} else if ( data.status == 'ACCOUNT_VERIFICATION_REQD' ) {  // User using an EIN needs to verify account number
					// Show the "Verify account" modal
					application.dynamicModal.show( {
						modalTitle:		'Verify your identity',
						modalClass:		'auth-modal-register-success auth-modal-account-verification-reqd',
						modalHard:		true,
						modalAjaxURL: 	'/individual/auth/accounts/verify-identity',
						modalAjaxType:	'post',
						modalAjaxData:	data,
						modalAjaxWait:	true,
						modalAjaxSuccess:	function() {
							let $newlyLoadedForm = $('.modal form.auth-modal-form');
							self.monitorInputsAndControlSubmitButton( $newlyLoadedForm );
						}
					} );
				
				} else if ( data.status == 'SUCCESS' ) { // User skips LexisNexis flow
					// Show the "Set password" modal
					application.dynamicModal.show( {
						modalTitle:			'Login credentials',
						modalClass:			'auth-modal-register-success auth-modal-set-password',
						modalHard:			true,
						modalAjaxURL: 		'/individual/auth/accounts/login-set-password',
						modalAjaxType:		'post',
						modalAjaxData:		data,
						modalAjaxWait:		true,
						modalAjaxSuccess:	function() {
							let $newlyLoadedForm = $('.modal form.auth-modal-form');
							self.monitorInputsAndControlSubmitButton( $newlyLoadedForm );
						}
					} );

				}

				break;

			case 'verifyAccountSuccess':
				if ( data.status == 'REQUEST_MFA') {  // Account verified, show MFA modal

					// Store the result in session storage
					sessionStorage.setItem( 'mfaRequestResult', JSON.stringify( data ) );

					// Show the next modal
					application.dynamicModal.show( {
						modalTitle:		'Verify your identity',
						modalClass:		'auth-modal-verify-account-success auth-modal-request-mfa',
						modalHard:		true,
						modalAjaxURL: 	'/individual/auth/accounts/login-mfa-request-code',
						modalAjaxType:	'post',
						modalAjaxData:	data,
						modalAjaxWait:	true
					} );

				} else if ( data.status == 'ACCOUNT_VERIFICATION_REQD' ) {  // User got the EIN wrong, try one more time before being locked out
					// Show the "Verify account" modal
					application.dynamicModal.show( {
						modalTitle:		'Verify your identity',
						modalClass:		'auth-modal-verify-account-success auth-modal-account-verification-reqd',
						modalHard:		true,
						modalAjaxURL: 	'/individual/auth/accounts/verify-identity',
						modalAjaxType:	'post',
						modalAjaxData:	data,
						modalAjaxWait:	true,
						modalAjaxSuccess:	function() {
							let $newlyLoadedForm = $('.modal form.auth-modal-form');

							// Show error message
							$newlyLoadedForm.addClass('server-error');

							self.monitorInputsAndControlSubmitButton( $newlyLoadedForm, true );
						}
					} );
				}

				break;

			case 'lexisNexisVerifySuccess':
				
				if ( data.status == 'LEXIS_NEXIS_VERIFY_INPROGRESS' || data.status == 'LEXIS_NEXIS_VERIFY' ) {  // More questions are required
					sessionStorage.setItem( 'lexisNexisChallenge', JSON.stringify( data.userVerificationChallenge ) );
					sessionStorage.setItem( 'lexisNexisQuestionNumber', $form.find('.question-number').last().text() );

					console.log('data.stateExpiresInSecs', data.stateExpiresInSecs)
					if( data.stateExpiresInSecs !== null ) {
						this.lexusNexisTimeAllowed = Math.min( 120, data.stateExpiresInSecs );
						console.log( 'new lexusNexisTimeAllowed:', this.lexusNexisTimeAllowed, data.stateExpiresInSecs )
					}

					if ( self.lexusNexisQuestionTimeoutModal === undefined ) {
						self.lexusNexisQuestionTimeoutModal = {
							modalTitle:		'Login',
							modalClass:		'auth-modal-lexis-nexis-verify-success auth-modal-lexis-nexis-timed-out',
							modalAjaxURL: 	'/individual/auth/accounts/login',
							modalAjaxType:	'post',
							modalAjaxWait:	true
						};
					}
					
					// Show the "LexisNexis questions" modal
					application.dynamicModal.show( {
						modalTitle:		'Verify your identity <span class="time-remaining">(' + self.getPrettyTimeRemaining() + ')</span>',
						modalClass:		'auth-modal-lexis-nexis-verify-success auth-modal-lexis-nexis-verify',
						modalHard:		true,
						modalAjaxURL: 	'/individual/auth/accounts/verify-identity-questions',
						modalAjaxType:	'post',
						modalAjaxData:	data,
						modalAjaxWait:	true
					} );

				} else if ( data.status == 'REQUEST_MFA' ) {   // MFA required
					// Clear lexisNexisQuestionNumber session storage
					sessionStorage.removeItem( 'lexisNexisQuestionNumber' );
					
					// Store the result in session storage
					sessionStorage.setItem( 'mfaRequestResult', JSON.stringify( data ) );

					// Show the next modal
					application.dynamicModal.show( {
						modalTitle:		'Verify your identity',
						modalClass:		'auth-modal-lexis-nexis-verify-success auth-modal-request-mfa',
						modalHard:		true,
						modalAjaxURL: 	'/individual/auth/accounts/login-mfa-request-code',
						modalAjaxType:	'post',
						modalAjaxData:	data,
						modalAjaxWait:	true
					} );

				} else {  // Verification complete
					// Show the "Set password" modal
					application.dynamicModal.show( {
						modalTitle:			'Login credentials',
						modalClass:			'auth-modal-lexis-nexis-verify-success auth-modal-set-password',
						modalHard:			true,
						modalAjaxURL: 		'/individual/auth/accounts/login-set-password',
						modalAjaxType:		'post',
						modalAjaxData:		data,
						modalAjaxWait:		true,
						modalAjaxSuccess:	function() {
							let $newlyLoadedForm = $('.modal form.auth-modal-form');
							self.monitorInputsAndControlSubmitButton( $newlyLoadedForm );
						}
					} );

					sessionStorage.removeItem( 'lexisNexisChallenge' );
					sessionStorage.removeItem( 'lexisNexisQuestionNumber' );
				}

				break;

			case 'mfaRequested':
				// Clear the login timer
				clearInterval( self.loginTimer );
				
				// Store the chosen MFA option in session storage
				let mfaChosenFactor = {
					"factorId":		$form.find('input#mfa-chosen-factor-id').val(),
					"factorType":	$form.find('input#mfa-chosen-factor-type').val(),
					"factorSample":	$form.find('input#mfa-chosen-factor-sample').val()
				};

				// If data.stateExpiresInSecs exists
				if ( data.stateExpiresInSecs ) {
					// Store the expiration time in session storage
					sessionStorage.setItem( 'mfaExpirationTime', data.stateExpiresInSecs );
				}

				// Store current epoch time in session storage
				sessionStorage.setItem( 'mfaRequestedTime', Date.now() );
				sessionStorage.setItem( 'mfaChosenFactor', JSON.stringify( mfaChosenFactor ) );

				// Show the next modal
				application.dynamicModal.show( {
					modalTitle:			'Enter authorization code',
					modalClass:			'auth-modal-mfa-requested auth-modal-mfa-verify-code',
					modalHard:			true,
					modalAjaxURL: 		'/individual/auth/accounts/login-mfa-verify-code',
					modalAjaxType:		'post',
					modalAjaxData:		data,
					modalAjaxWait:		true,
					modalAjaxSuccess:	function() {
						let $newlyLoadedForm = $('.modal form.auth-modal-form');
						self.monitorInputsAndControlSubmitButton( $newlyLoadedForm );

						// Clear the loginTimer
						clearInterval( self.loginTimer );
					}
				} );

				break;

			case 'mfaVerify':
				// Clear the mfaInputTimer
				clearInterval( self.mfaInputTimer );

				// Navigate to /individual/accounts
				if ( data.status == 'MFA_VERIFIED_NEEDS_LOGIN' ) {  // Login help flow, take to "account found" modal
					
					// Show the next modal
					application.dynamicModal.show( {
						modalTitle:		'Login help',
						modalClass:		'auth-modal-mfa-verify-success auth-modal-mfa-verified-needs-login',
						modalHard:		true,
						modalAjaxURL: 	'/individual/auth/accounts/login-help-found-email',
						modalAjaxType:	'post',
						modalAjaxData:	data,
						modalAjaxWait:	true
					} );

				} else if ( data.status == 'SUCCESS' ) {  // Login complete, go to accounts
					self.loginComplete();
				}

				break;

			case 'setPasswordSuccess':
				if ( data.status == 'SUCCESS' ) {  // No issues, redirect to accounts page
					self.loginComplete();
				} else if ( data.status == 'REQUEST_MFA' ) {  // MFA required
					// Store the result in session storage
					sessionStorage.setItem( 'mfaRequestResult', JSON.stringify( data ) );

					// Show the next modal
					application.dynamicModal.show( {
						modalTitle:		'Verify your identity',
						modalClass:		'auth-modal-set-password-success auth-modal-request-mfa',
						modalHard:		true,
						modalAjaxURL: 	'/individual/auth/accounts/login-mfa-request-code',
						modalAjaxType:	'post',
						modalAjaxData:	data,
						modalAjaxWait:	true
					} );
				}

				break;

			case 'updateContactInfoSuccess':
				if ( data.status == 'LEXIS_NEXIS_VERIFY' ) {  // Go to LexisNexis flow to verify identity
					// Store LexisNexis verification challenge in session storage
					sessionStorage.setItem( 'lexisNexisChallenge', JSON.stringify( data.userVerificationChallenge ) );
					sessionStorage.setItem( 'updateEmail', $form.find('input.confirm-email').val() );
					sessionStorage.setItem( 'updatePhone', $form.find('input.confirm-phone').val() );

					console.log('data.stateExpiresInSecs', data.stateExpiresInSecs)
					if( data.stateExpiresInSecs !== null ) {
						this.lexusNexisTimeAllowed = Math.min( 180, data.stateExpiresInSecs );
						console.log( 'new lexusNexisTimeAllowed:', this.lexusNexisTimeAllowed, data.stateExpiresInSecs )
					}

					self.lexusNexisQuestionTimeoutModal = {
						modalTitle:		'Login',
						modalClass:		'auth-modal-update-contact-info-success auth-modal-lexis-nexis-timed-out',
						modalAjaxURL: 	'/individual/auth/accounts/login',
						modalAjaxType:	'post',
						modalAjaxWait:	true
					};

					// Show the "LexisNexis questions" modal
					application.dynamicModal.show( {
						modalTitle:		'Verify your identity <span class="time-remaining">(' + self.getPrettyTimeRemaining() + ')</span>',
						modalClass:		'auth-modal-update-contact-info-success auth-modal-lexis-nexis-verify',
						modalHard:		true,
						modalAjaxURL: 	'/individual/auth/accounts/verify-identity-questions',
						modalAjaxType:	'post',
						modalAjaxData:	data,
						modalAjaxWait:	true
					} );
				} else if ( data.status == 'ACCOUNT_VERIFICATION_REQD' ) {  // User using an EIN needs to verify account number
					// Show the "Verify account" modal
					application.dynamicModal.show( {
						modalTitle:		'Verify your identity',
						modalClass:		'auth-modal-update-contact-info-success auth-modal-account-verification-reqd',
						modalHard:		true,
						modalAjaxURL: 	'/individual/auth/accounts/verify-identity',
						modalAjaxType:	'post',
						modalAjaxData:	data,
						modalAjaxWait:	true,
						modalAjaxSuccess:	function() {
							let $newlyLoadedForm = $('.modal form.auth-modal-form');
							self.monitorInputsAndControlSubmitButton( $newlyLoadedForm );
						}
					} );
				} else if ( data.status == 'REQUEST_MFA' ) {  // MFA required
					// Store the result in session storage
					sessionStorage.setItem( 'mfaRequestResult', JSON.stringify( data ) );

					// Show the next modal
					application.dynamicModal.show( {
						modalTitle:		'Verify your identity',
						modalClass:		'auth-modal-update-contact-info-success auth-modal-request-mfa',
						modalHard:		true,
						modalAjaxURL: 	'/individual/auth/accounts/login-mfa-request-code',
						modalAjaxType:	'post',
						modalAjaxData:	data,
						modalAjaxWait:	true
					} );
				}

				break;

			case 'loginHelpSuccess':
				if ( data.status == 'STAGED_USER' ) {  // Staged user
					sessionStorage.setItem( 'foundExistingEmail', $form.find('input.form-control-email').val() );
					// Show the next modal
					application.dynamicModal.show( {
						modalTitle:		'Your email address was found',
						modalClass:		'auth-modal-login-help-success auth-modal-staged-user',
						modalHard:		true,
						modalAjaxURL: 	'/individual/auth/accounts/login-help-staged-profile',
						modalAjaxType:	'post',
						modalAjaxData:	data,
						modalAjaxWait:	true
					} );
				} else {
					// Store the result in session storage
					sessionStorage.setItem( 'mfaRequestResult', JSON.stringify( data ) );
					sessionStorage.setItem( 'foundExistingEmail', $form.find('input.form-control-email').val() );

					// Show the next modal
					application.dynamicModal.show( {
						modalTitle:		'Login help',
						modalClass:		'auth-modal-login-help-success auth-modal-request-mfa',
						modalHard:		true,
						modalAjaxURL: 	'/individual/auth/accounts/login-mfa-request-code',
						modalAjaxType:	'post',
						modalAjaxData:	data,
						modalAjaxWait:	true
					} );
				}

				break;

			case 'changePasswordSuccess':
				
				// Show the next modal
				application.dynamicModal.show( {
					modalTitle:			'Login',
					modalClass:			'auth-modal-change-password-success',
					modalAjaxURL: 		'/individual/auth/accounts/login',
					modalAjaxType:		'post',
					modalAjaxWait:		true,
					modalAjaxSuccess:	function() {
						let $modal = $('.modal'),
							$newlyLoadedForm = $('.modal form.auth-modal-form');

						// Set the server-error-text text and make it visible
						$modal.find('.server-error-text').text( 'Your password has been reset, please enter your new password.' ).show();

						self.monitorInputsAndControlSubmitButton( $newlyLoadedForm );
					}
				} );

				break;

			default:

				console.log('No onSuccess function defined for this form.');

				break;
		}
	};

	self.processError = function( data, onError, $form )
	{

		if ( typeof data.responseJSON === 'undefined' ) {  // No responseJSON means an unknown error
			self.clearAuthentication();  // Clear the authentication process, resetting timers and session storage
			self.error( '<p>' + genericErrorText + ' [Error code: 4]</p>' );
		} else if (
				data.responseJSON.errorCode == 'LOCKED_USER' ||
				data.responseJSON.errorCode == 'LEXIS_NEXIS_LOCKED_OUT'
			)
		{  // Locked out
			self.clearAuthentication();  // Clear the authentication process, resetting timers and session storage
			// Show the locked modal
			application.dynamicModal.show( {
				modalTitle:		'Account locked',
				modalClass:		'auth-modal-error auth-modal-locked-user',
				modalHard:		true,
				modalAjaxURL: 	'/individual/auth/accounts/account-locked',
				modalAjaxType:	'post',
				modalAjaxWait:	true
			} );
		} else if ( data.responseJSON.errorCode == 'SUSPENDED_USER' ) {  // User suspended
			self.clearAuthentication();  // Clear the authentication process, resetting timers and session storage

			// Show the suspended modal
			application.dynamicModal.show( {
				modalTitle:		'Account suspended',
				modalClass:		'auth-modal-error auth-modal-suspended-user',
				modalHard:		true,
				modalAjaxURL: 	'/individual/auth/accounts/account-suspended',
				modalAjaxType:	'post',
				modalAjaxWait:	true,
				modalAjaxSuccess:	function() {
					let $newlyLoadedModal			= $('.modal'),
						suspensionExpiresDate		= new Date( +data.responseJSON.otherInformation.SUSPENSION_EXPIRES_AT ),  // Stored as epoch time
						$suspensionExpiresDateDOM	= $newlyLoadedModal.find('.account-suspended-until');

					// Set the suspension expiration date using this format: 9:16 a.m. 04/10/2023
					let ampm = suspensionExpiresDate.getHours() >= 12 ? 'p.m.' : 'a.m.',
						hours = suspensionExpiresDate.getHours() % 12;

					hours = hours ? hours : 12;  // the hour '0' should be '12'

					let minutes = suspensionExpiresDate.getMinutes();

					minutes = minutes < 10 ? '0'+minutes : minutes;

					let strTime = hours + ':' + minutes + ' ' + ampm;

					$suspensionExpiresDateDOM.text( strTime + ' ' + ("0" + (suspensionExpiresDate.getMonth() + 1)).slice(-2) + '/' + ("0" + suspensionExpiresDate.getDate()).slice(-2) + '/' + suspensionExpiresDate.getFullYear() );
				}
			} );

		} 
		else if ( data.responseJSON.errorCode == 'FAILED_LEXISNEXIS_CHECK' ) {
			// Check form action and if contains modifyFactor, then show user info update fail modal
			if( $form[0].action.indexOf( 'modifyFactor' ) !== -1 )
			{	
				application.dynamicModal.show( {
					modalTitle:		"Unable to update your information",
					modalClass:		'auth-modal-error auth-modal-failed-lexisnexis-check',
					modalHard:		true,
					modalAjaxURL: 	'/individual/auth/accounts/login-update-contact-info-error',
					modalAjaxType:	'post',
					modalAjaxWait:	true
				} );
			}
		}
		// Invalid credentials
		else if ( data.responseJSON.errorCode == 'INTERNAL_SYSTEM_ERROR' ) {  // Internal server error
			self.clearAuthentication();  // Clear the authentication process, resetting timers and session storage
			self.error( '<p>' + genericErrorText + ' [Error code: 5]</p>' );
		}
		else if ( data.responseJSON.errorCode == 'UNAUTHORIZED' || data.responseJSON.errorCode == 'LOGIN_SESSION_EXPIRED' ) {

			let userStatus = "";
			
			// If data.responseJSON.otherInformation is not null and data.responseJSON.otherInformation.USER_STATUS exists
			if ( data.responseJSON.otherInformation && data.responseJSON.otherInformation.WORKFLOW_TYPE ) {
				userStatus = data.responseJSON.otherInformation.WORKFLOW_TYPE;
			} else if ( data.responseJSON.errorSummary && data.responseJSON.errorSummary == 'ONLINE_ACCESS_NOT_ALLOWED' ) {
				userStatus = 'ONLINE_ACCESS_NOT_ALLOWED';
			}

			if ( data.responseJSON.errorCode == 'UNAUTHORIZED' && userStatus != 'ONLINE_ACCESS_NOT_ALLOWED' ) {
				sessionStorage.setItem( 'verifyError', true );
				// Send GA event
				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({
					'event':	'LexisNexisSoftFail',
					'category':	'modalAuthEvents',
					'action':	'modalAuthError',
					'label':	'Unable to verify identity'
				});
			} else if ( data.responseJSON.errorCode == 'UNAUTHORIZED' && userStatus == 'ONLINE_ACCESS_NOT_ALLOWED' ) {
				sessionStorage.setItem( 'onlineAccessNotAllowed', true );
			} else if ( data.responseJSON.errorCode == 'LOGIN_SESSION_EXPIRED' ) {
				if ( application.site == "individual" )
					sessionStorage.setItem( 'sessionExpired', true );
			}

			// If userStatus is "NEW_LOGIN" 
			if ( userStatus == 'NEW_LOGIN' || userStatus == 'USER_HELP' ) {

				sessionStorage.removeItem( 'lexisNexisQuestionNumber' );

				application.dynamicModal.show( {
					modalTitle:			'Login',
					modalClass:			'auth-modal-error auth-modal-new-login-user-help',
					modalAjaxURL: 		'/individual/auth/accounts/login',
					modalAjaxType:		'post',
					modalAjaxWait:		true
				} );

			} else {

				sessionStorage.removeItem( 'lexisNexisQuestionNumber' );
				application.dynamicModal.show( {
					modalTitle:		'Register',
					modalClass:		'auth-modal-error auth-modal-unauthorized-login-session-expired',
					modalHard:		true,
					modalAjaxURL: 	'/individual/auth/accounts/register',
					modalAjaxType:	'post',
					modalBody:		"<div class='putnam-loader-wrapper' style='min-height:413px;'><div class='putnam-loader-sm'></div></div>",
					modalAjaxSuccess:	function() {
						let $newlyLoadedForm = $('.modal form.auth-modal-form');

						self.monitorInputsAndControlSubmitButton( $newlyLoadedForm );
					}
				} );

			}
		} 
		else if ( data.responseJSON.errorCode == 'INVALID_REQUEST' ) {  // Some form error reported by back-end

			$form.closest('.modal-body').find('.putnam-loader-wrapper').remove(); // Remove the loader
			// Show a generic error, if desired
			//$form.find('.server-error-text').html( 'There was an issue with one of your inputs below. Please address and retry.' ).show();

			switch ( data.responseJSON.errorSummary ) {
				case 'Invalid Phone Input':
					// Store the old phone number
					let oldPhone = $form.find('input[type="tel"]').val();

					// Find the phone input and add the error class, then show the error message
					$form
						.find('input[type="tel"]')
						.addClass('is-invalid')
						.focus()
						.css( { "border-color": "#95142b", "box-shadow": "0 0 0 0.2rem rgba(149,20,43,.25)" } )
						.on('keyup paste', function( event ){

							// If key press is not "enter"
							if ( event.which != 13 ) {

								let newPhone = $(this).val();

								// If new input is different from old input, remove the error class and message
								if ( newPhone != oldPhone ) {
									$(this).css( { "border-color": "", "box-shadow": "" } ).removeClass('is-invalid');
								}
							}

						})
						.siblings('.invalid-feedback')
						.html('Please enter a valid phone number.');

					break;
				
				default:
					self.error( '<p>' + genericErrorText + ' [Error code: 9]</p>' );
					break;
			}

		} else {
			//console.log( 'onError', onError );
			switch ( onError ) {
				case 'loginError':
					if ( data.responseJSON.errorCode == 'CSU_USER_NEEDS_REGISTRATION' || data.responseJSON.errorCode == 'USER_NEEDS_REGISTRATION' ) {  // User needs to register with the new system

						application.dynamicModal.show( {
							modalTitle:		'Register',
							modalClass:		'auth-modal-error auth-modal-csu-user-needs-registration',
							modalHard:		true,
							modalAjaxURL: 	'/individual/auth/accounts/register',
							modalAjaxType:	'post',
							modalBody:		"<div class='putnam-loader-wrapper' style='min-height:413px;'><div class='putnam-loader-sm'></div></div>",
							modalAjaxSuccess:	function() {
								let $newlyLoadedForm = $('.modal form.auth-modal-form');
	
								// Set the server-error-text text and make it visible
								$newlyLoadedForm.find('.server-error-text').html( "We have made enhancements to this website to further secure your account. Please re-register your account to create a new username and password." ).show();
	
								self.monitorInputsAndControlSubmitButton( $newlyLoadedForm );
							}
						} );

					} else if ( data.responseJSON.errorCode == "INVALID_LOGIN_CSU" ) {

						application.dynamicModal.show( {
							modalTitle:		'We were unable to verify your identity',
							modalClass:		'auth-modal-error auth-modal-invalid-login-csu',
							modalAjaxURL: 	'/individual/auth/accounts/account-error',
							modalAjaxType:	'post',
							modalBody:		"<div class='putnam-loader-wrapper' style='min-height:172px;'><div class='putnam-loader-sm'></div></div>"
						} );

					} else {
						// Show error message
						$form.find('.server-error-text').text( 'Invalid username or password.' ).show();

						self.monitorInputsAndControlSubmitButton( $form, true );
					}
					
					break;

				case 'registerError':
					if ( data.responseJSON.errorCode == 'EXISTING_LOGIN' ) {  // Someone already registered with that email

						// Set the server-error-text text and make it visible
						$form.find('.server-error-text').html( "This email address is already associated with an existing account. Please use a different email address." ).show();

						// Find the input name of email, clear it and focus on it
						$form.find('input[name="email"]').val('').focus();

						self.monitorInputsAndControlSubmitButton( $form, true );

					} else if ( data.responseJSON.errorCode == 'INVALID_USER_ID' ) {

						application.dynamicModal.show( {
							modalTitle:		"Unable to update your information",
							modalClass:		'auth-modal-error auth-modal-unable-to-register-invalid-user-id',
							modalHard:		true,
							modalAjaxURL: 	'/individual/auth/accounts/login-update-contact-info-error',
							modalAjaxType:	'post',
							modalAjaxWait:	true
						} );

					} else if ( data.responseJSON.errorCode == 'LEXIS_NEXIS_NOT_FOUND' || data.responseJSON.errorCode == 'UNABLE_TO_REGISTER' ) {  // User not found in Lexis Nexis, cannot register online, show error modal to call help desk

						application.dynamicModal.show( {
							modalTitle:		"You can't register online",
							modalClass:		'auth-modal-error auth-modal-unable-to-register',
							modalHard:		true,
							modalAjaxURL: 	'/individual/auth/accounts/register-error',
							modalAjaxType:	'post',
							modalAjaxWait:	true
						} );
	
					} else if ( data.responseJSON.errorCode == 'SSN_ALREADY_REGISTERED' ) {

						application.dynamicModal.show( initialAuthenticationModalObject );

						let f = $('.modal form.auth-modal-form');
						f.addClass('server-error').find('.server-error-text').html( 'This account is already registered. Please login with the email address used while registering. If you\'ve forgotten the email address, call <a href="tel:+1-888-478-8626" class="nobr">1-888-478-8626</a> for assistance.' ).show();
						f.find('input[name="userName"]').val( '' ).focus();

						self.monitorInputsAndControlSubmitButton( f );

					} else if ( data.responseJSON.errorCode == 'SSN_EMAIL_ALREADY_REGISTERED' ) {

						application.dynamicModal.show( initialAuthenticationModalObject );

						let alreadyRegisteredEmail = $form.find('input[name="email"]').val();

						let f = $('.modal form.auth-modal-form');
						f.addClass('server-error').find('.server-error-text').html( 'This account is already registered. Please login with the email address used while registering. If you\'ve forgotten the email address, call <a href="tel:+1-888-478-8626" class="nobr">1-888-478-8626</a> for assistance.' ).show();
						f.find('input[name="userName"]').val( alreadyRegisteredEmail ).focus();

						self.monitorInputsAndControlSubmitButton( f );

					} else {
						// Show error message
						$form.addClass('server-error').find('.server-error-text').text( 'No account was found in the system. Please check the information below and try again.' ).show();

						self.monitorInputsAndControlSubmitButton( $form, true );
					}

					break;

				case 'mfaRequestError':
					self.clearAuthentication();  // Clear the authentication process, resetting timers and session storage
					self.error( '<p>' + genericErrorText + ' [Error code: 3]</p>' );

					break;

				case 'mfaVerifyError':
					// Show error message
					$form.addClass('server-error');

					self.monitorInputsAndControlSubmitButton( $form, true );

					break;
					
				case 'verifyAccountError':

					// Show the account locked error modal
					application.dynamicModal.show( {
						modalTitle:		'Account locked',
						modalClass:		'auth-modal-error auth-modal-verify-account-error',
						modalHard:		true,
						modalAjaxURL: 	'/individual/auth/accounts/account-locked',
						modalAjaxType:	'post',
						modalAjaxWait:	true
					} );

					break;

				case 'lexisNexisVerifyError':
					
					if ( data.responseJSON.errorCode == 'EXISTING_LOGIN' ) { // Someone already has that email address
						let emailInput	= sessionStorage.getItem( 'updateEmail' ),
							phoneInput	= sessionStorage.getItem( 'updatePhone' );

						// Show the "Update your contact info" modal with error message
						application.dynamicModal.show( {
							modalTitle:		'Update your contact info',
							modalClass:		'auth-modal-error auth-modal-lexis-nexis-verify-error',
							modalHard:		true,
							modalAjaxURL: 	'/individual/auth/accounts/login-update-contact-info',
							modalAjaxType:	'post',
							modalAjaxWait:	true,
							modalAjaxSuccess:	function() {
								let $newlyLoadedForm = $('.modal form.auth-modal-form');
	
								// Set the server-error-text text and make it visible
								$newlyLoadedForm.find('.step-1').prepend( "<p class='server-error-text d-block'>That email address is already associated with an existing account. Please use a different email address.</p>" );

								// Set the input values to the one that was already entered
								$newlyLoadedForm.find('input[name="phoneNumber"]').val( phoneInput );
								$newlyLoadedForm.find('input[name="email"]').val( emailInput ).focus();

								// Disable the submit button of the new form
								self.monitorInputsAndControlSubmitButton( $newlyLoadedForm, true );
							}
						} );

					} else if ( data.responseJSON.errorCode == 'INVALID_USER_ID' ) {

						application.dynamicModal.show( {
							modalTitle:		"Unable to update your information",
							modalClass:		'auth-modal-error auth-modal-lexis-nexis-verify-error-invalid-user-id',
							modalHard:		true,
							modalAjaxURL: 	'/individual/auth/accounts/login-update-contact-info-error',
							modalAjaxType:	'post',
							modalAjaxWait:	true
						} );

					} else {

						// Show the account locked error modal
						application.dynamicModal.show( {
							modalTitle:		'Account locked',
							modalClass:		'auth-modal-error auth-modal-lexis-nexis-verify-error-locked',
							modalHard:		true,
							modalAjaxURL: 	'/individual/auth/accounts/account-locked',
							modalAjaxType:	'post',
							modalAjaxWait:	true
						} );
						
						sessionStorage.removeItem( 'lexisNexisChallenge' );
						sessionStorage.removeItem( 'lexisNexisQuestionNumber' );
					}

					break;
				
				case 'updateContactInfoError':

					if ( data.responseJSON.errorCode == 'SAME_CONTACT_INFO' ) { // MFA required, but no info changed, so just show MFA window again
						
						// Show the MFA modal
						application.dynamicModal.show( {
							modalTitle:		'Verify your identity',
							modalClass:		'auth-modal-error auth-modal-update-contact-info-error-same-contact-info',
							modalHard:		true,
							modalAjaxURL: 	'/individual/auth/accounts/login-mfa-request-code',
							modalAjaxType:	'post',
							modalAjaxWait:	true
						} );

					} else if ( data.responseJSON.errorCode == 'EXISTING_LOGIN' ) { // Someone already has that email address
						
						let emailInput	= sessionStorage.getItem( 'updateEmail' ),
							phoneInput	= sessionStorage.getItem( 'updatePhone' );
						
						// Show the "Update your contact info" modal with error message
						application.dynamicModal.show( {
							modalTitle:		'Update your contact info',
							modalClass:		'auth-modal-error auth-modal-update-contact-info-error-existing-login',
							modalHard:		true,
							modalAjaxURL: 	'/individual/auth/accounts/login-update-contact-info',
							modalAjaxType:	'post',
							modalAjaxWait:	true,
							modalAjaxSuccess:	function() {
								let $newlyLoadedForm = $('.modal form.auth-modal-form');
	
								// Set the server-error-text text and make it visible
								$newlyLoadedForm.find('.step-1').prepend( "<p class='server-error-text d-block'>That email address is already associated with an existing account. Please use a different email address.</p>" );

								// Set the input values to the one that was already entered
								$newlyLoadedForm.find('input[name="phoneNumber"]').val( phoneInput );
								$newlyLoadedForm.find('input[name="email"]').val( emailInput ).focus();

								// Disable the submit button of the new form
								self.monitorInputsAndControlSubmitButton( $newlyLoadedForm, true );
							}
						} );

					} else if ( data.responseJSON.errorCode == 'LEXIS_NEXIS_NOT_FOUND' || data.responseJSON.errorCode == 'INVALID_USER_ID' ) { // User not found in Lexis Nexis, cannot update contact info online, show error modal to call help desk

						application.dynamicModal.show( {
							modalTitle:		"Unable to update your information",
							modalClass:		'auth-modal-error auth-modal-update-contact-info-error-lexis-nexis-not-found',
							modalHard:		true,
							modalAjaxURL: 	'/individual/auth/accounts/login-update-contact-info-error',
							modalAjaxType:	'post',
							modalAjaxWait:	true
						} );
					
					} else {
						self.clearAuthentication();  // Clear the authentication process, resetting timers and session storage
						self.error( '<p>' + genericErrorText + ' [Error code: 7]</p>' );
					}

					break;

				case 'loginHelpError':
					
					if ( data.responseJSON.errorCode == 'USER_NEEDS_REGISTRATION' ) { // User needs to register with the new system | This is duplicated below because it might not be coming back as an error, so might not be caught below
						application.dynamicModal.show( {
							modalTitle:		'Your account was found',
							modalClass:		'auth-modal-error auth-modal-user-needs-registration',
							modalHard:		true,
							modalAjaxURL: 	'/individual/auth/accounts/login-help-staged-profile',
							modalAjaxType:	'post',
							modalAjaxWait:	true
						} );
					} else {
						self.clearAuthentication();  // Clear the authentication process, resetting timers and session storage
						self.error( '<p>' + genericErrorText + ' [Error code: 6]</p>' );
					}

					break;

				case 'changePasswordError':
					self.clearAuthentication();  // Clear the authentication process, resetting timers and session storage
					self.error( '<p>' + genericErrorText + ' [Error code: 8]</p>' );

					break;
					
				default:
					self.clearAuthentication();  // Clear the authentication process, resetting timers and session storage
					self.error( '<p>' + genericErrorText + ' [Error code: 2]</p>' );

					break;
			}
		}
	};

	// Control whether or not the submit button is disabled or enabled
	self.monitorInputsAndControlSubmitButton = function( $form, disableSubmitAfterError = false )
	{

		let $submitButton		= $form.find('button[type="submit"]'),
			$requiredInputs		= $form.find('input[required]:visible'),
			validityRequired	= $form.hasClass('validity-required');

		// If we should disable the submit button, do that
		if ( disableSubmitAfterError ) {
			// Disable submit button
			$form.find('button[type="submit"]')
				.prop('disabled', true)
				.removeClass('btn-primary')
				.addClass('btn-dead');

			// Empty MFA inputs and focus on the first one
			if ( $form.find('input.form-control-mfa').length ) {
				$form.find('input.form-control-mfa').val('').eq(0).focus();
			}
		}

		// Remove loader if it exists
		$form.closest('.modal-body').find('.putnam-loader-wrapper').remove();

		// Focus the first input without any value
		$requiredInputs.each(function() {
			if ( !$(this).val() ) {
				$(this).focus();
				return false;
			}
		});
			
		var checkFormValidity = function( e ) {
			if ( e !== undefined && e.keyCode == 13 ) { return; }  // Ignore enter key
			
			// Set a timer for 100ms and fire the function that checks if all required inputs have a value
			if ( typeof validateInputsTimer !== 'undefined' ) {
				clearTimeout( validateInputsTimer );
			}
			validateInputsTimer = setTimeout( function() {

				let allRequiredInputsHaveValues = true;
	
				$requiredInputs.each(function() {
					if ( !$(this).val() ) {
						allRequiredInputsHaveValues = false;
					}
				});

				if (
					allRequiredInputsHaveValues &&
					(
						!validityRequired ||
						(
							validityRequired &&
							$form[0].checkValidity()
						) 
					)
				) {
					$submitButton
						.removeClass('btn-dead')
						.addClass('btn-primary')
						.prop('disabled', false);
				} else {
					$submitButton
						.removeClass('btn-primary')
						.addClass('btn-dead')
						.prop('disabled', true);
				}
			}, 100 );
		}

		// If ALL required inputs have a value on any keyup, enable the submit button, unless validityRequired is true, in which case, check validity as well
		$requiredInputs.on( 'blur input keyup', checkFormValidity);

		// Do an initial check to see if all required inputs have a value | This is mostly to fix a FF bug where the above keyup event doesn't fire
		checkFormValidity();
	}

	self.error = function( errorText='' )
	{
		if ( errorText ) {
			errorModalObject.modalBody = '<p>' + errorText + '</p>';
		}
		application.dynamicModal.show( errorModalObject );
	};

	self.loginComplete = function()
	{
		if ( application.site == "individual" ) {
			
			// If the current page contains /individual/accounts, reload, otherwise go to /individual/accounts
			if ( window.location.href.indexOf('/individual/accounts') > -1 ) {
				window.location.reload();
			} else {
				window.location.href = '/individual/accounts';
			}

			// Clear all the timers and session storage items
			self.clearAuthentication();
		}
	};

	self.sessionTimeoutWarning = function()
	{
		if ( application.site == "individual" || application.site == "advisor" ) {
			// Show the session timeout warning modal
			var modal = application.dynamicModal.show( {
				modalTitle:		'Session warning',
				modalClass:		'auth-modal-session-warning',
				modalBody:		'<p>Your session is about to expire. Do you want to extend the session?</p>',
				modalFooter:	'<button type="button" class="btn btn-outline-primary accounts-logout" style="outline-color:#006EAD !important;">No, logout</button><button type="button" class="btn btn-primary accounts-extend-session" style="background-color:#006EAD !important;">Yes, extend session</button>'
			} );

			modal.on( 'click', '.close', function() {
				self.extendCurrentSession();
			} );
		}
	};

	self.extendCurrentSession = function()
	{
		if ( application.site == "individual" || application.site == "advisor" ) {
			clearInterval( self.finalTimeoutTimer );

			self.identityProvider.extendSession();

			// Close any open modal
			application.dynamicModal.hide();
		}
	};

	self.sessionTimedOut = function()
	{
		if ( application.site == "individual" || application.site == "advisor" ) {
			clearInterval( self.finalTimeoutTimer );

			// Set sessionExpired sessionStorage item to true
			if( application.site == "individual" )
				sessionStorage.setItem( 'sessionExpired', true );
			// Redirect to /individual/logout/
			// window.location.href = application.site == "individual" ? '/individual/logout/' : '/advisor/logout/';
			self.logout();
		}
	};

	self.clearAuthentication = function() {
		if ( application.site == "individual" ) {
			// Clear session storage
			self.clearAuthenticationSessionStorage();

			// Clear any timers
			self.clearAuthenticationTimers();
		}
	};

	self.clearAuthenticationSessionStorage = function() {
		sessionStorage.removeItem( 'mfaChosenFactor' );
		sessionStorage.removeItem( 'mfaRequestResult' );
		sessionStorage.removeItem( 'mfaRequestedTime' );
		sessionStorage.removeItem( 'mfaExpirationTime' );
		sessionStorage.removeItem( 'foundExistingEmail' );
		sessionStorage.removeItem( 'registerNewEmail' );
		sessionStorage.removeItem( 'updateEmail' );
		sessionStorage.removeItem( 'updatePhone' );
		sessionStorage.removeItem( 'lexisNexisChallenge' );
		sessionStorage.removeItem( 'lexisNexisQuestionNumber' );
	};

	self.clearAuthenticationTimers = function() {
		// Clear any timers
		clearInterval( self.loginTimer );
		clearInterval( self.mfaRequestTimer );
		clearInterval( self.mfaInputTimer );
		clearInterval( self.lexisNexisTimer );
		clearInterval( self.finalTimeoutTimer );
	};

	self.logout = function()
	{
		
		// Clear session storage
		self.clearAuthenticationSessionStorage();

		// Clear any timers
		self.clearAuthenticationTimers();

		// Clear Datadog session
		window.DD_RUM && window.DD_RUM.clearUser();
		
		if ( application.site == "individual") {
			self.identityProvider.loggedOut();

			$.ajax( {
				url: '/individual/logout',
				type: 'get',
				headers: {
					'X-XSRF-TOKEN': $.cookie('XSRF-TOKEN')
				},
				xhrFields: {
					withCredentials: true
				},
				success: function( response ) {
					// Redirect to homepage
					window.location.href = '/' + application.site + '/';
				},
				error: function( response ) {
					// Redirect to homepage
					window.location.href = '/' + application.site + '/';
				}
			} );
		}
		else if( application.site == "advisor"  )
		{
			self.identityProvider.loggedOut();

			window.location.href = '/advisor/logoutcda';
		}
	};

	self.handleIdleTimer = function( idle_minutes )
	{
		self.finalTimeoutTimer = setTimeout( function() {
			self.sessionTimedOut();
		}, 3 * 60 * 1000 ); // 3 minutes

		self.sessionTimeoutWarning();
	}

	self.getPrettyTimeRemaining = function()
	{
		return ( Math.floor( self.lexusNexisTimeAllowed / 60 ) + ':' + ( self.lexusNexisTimeAllowed % 60 < 10 ? '0' : '' ) + self.lexusNexisTimeAllowed % 60 );
	}

	ApplicationObject.call( this );
}

Authentication.prototype = Object.create( ApplicationObject.prototype );
